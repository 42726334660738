<!--
 * @Description: 工作台
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-09 14:08:54
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <!-- 标题 -->
    <van-row class="title">工作台</van-row>
    <!-- 通知 -->
    <van-notice-bar class="message"
                    left-icon="volume-o"
                    color="#CC9249"
                    background="#FEEFE8"
                    text="地锁设备异常，请帮助引导维修人员，并进行工单完成确认!" />
    <!-- 登录 -->
    <div class="register">
      <div class="big"
           @click="handleClickRegister">
        <div class="mid">
          <div class="small">
            <span>登记</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 功能按钮 -->
    <van-row class="bottom">
      <van-row type="flex"
               justify="space-between">
        <van-col v-for="(item,index) in list"
                 :key="index"
                 @click="handleClickItem(index)"
                 :span="12"
                 class="four">
          <van-row class="four-img">
            <van-image :src="item.img"></van-image>
          </van-row>
          <van-row class="four-text">
            <span>{{item.title}}</span>
          </van-row>
        </van-col>
      </van-row>
      <!-- 详情 -->
      <van-row class="information">
        <!-- 上面 -->
        <van-row class="information-top">
          <van-row class="information-top-lineOne">
            <van-col span="5">巡检员:</van-col>
            <van-col :span="17">{{userName}}</van-col>
            <van-col :span="2">
              <van-row class="loginOutButton"
                       @click="handleClickLoginOut">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 561.12 597.84">
                  <g id="图层_2"
                     data-name="图层 2">
                    <g id="图层_1-2"
                       data-name="图层 1">
                      <path class="cls-1"
                            d="M280.56,294.24a25.57,25.57,0,0,1-25.6-25.6V25.6a25.6,25.6,0,0,1,51.2,0v243a25.57,25.57,0,0,1-25.6,25.6Z" />
                      <path class="cls-1"
                            d="M280.56,597.84C125.84,597.84,0,472,0,317.28A279.9,279.9,0,0,1,149.92,69.12a25.64,25.64,0,1,1,23.92,45.36A228.77,228.77,0,0,0,51.2,317.36c0,126.48,102.88,229.36,229.36,229.36S509.92,443.84,509.92,317.36a229,229,0,0,0-122-202.64,25.62,25.62,0,0,1,24-45.28,280.21,280.21,0,0,1,149.2,247.84c0,154.72-125.84,280.56-280.56,280.56Z" />
                    </g>
                  </g>
                </svg>
              </van-row>
            </van-col>
          </van-row>
          <van-row class="information-top-lineTwo"
                   style="margin-top:10px">
            <van-col span="5">停车场:</van-col>
            <van-col span="19"
                     @click="parkPopShow = true">
              {{parkName}}
              <van-image class="information-top-lineTwo-img"
                         :src="require('@/assets/homePage/a.png')">
              </van-image>
            </van-col>
            <van-popup v-model="parkPopShow"
                       round
                       position="bottom">
              <van-picker show-toolbar
                          :columns="parkList"
                          @cancel="parkPopShow = false"
                          @confirm="onSelectPark" />
            </van-popup>
          </van-row>
          <van-row class="information-top-lineTwo">
            <van-col span="5">区 域:</van-col>
            <van-col span="19"
                     @click="handleClickQuyu">
              {{parkLayerName}}
              <van-image class="information-top-lineTwo-img"
                         :src="require('@/assets/homePage/a.png')">
              </van-image>
            </van-col>
            <van-popup v-model="parklayerPopShow"
                       round
                       position="bottom">
              <van-picker show-toolbar
                          :columns="parkLayerList"
                          @cancel="parklayerPopShow = false"
                          @confirm="onSelectParkLayer" />
            </van-popup>
          </van-row>
        </van-row>
        <!-- 下面 -->
        <van-row class="information-bottom">
          <van-row class="information-bottom-lineOne">
            <van-col span="11">
              <span class="big">{{berthageOnNum}}</span>
              /
              <span class="small">{{berthageNum}}</span>
            </van-col>
            <van-col span="2"
                     class="vertical"></van-col>
            <van-col span="11">
              <span class="big">{{totalReceivedMoneyAmount}}</span>
              /
              <span class="small">{{totalReceivableMoneyAmount}}</span>
            </van-col>
          </van-row>
          <van-row class="information-bottom-lineTwo">
            <van-col span="11">在停泊位/泊位数(个)</van-col>
            <van-col span="2"
                     class="vertical"></van-col>
            <van-col span="11">实收/应收(元)</van-col>
          </van-row>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      timedTask: null, // 定时任务
      userName: '', // 巡检员名
      parkPopShow: false, // 停车场下拉框
      parklayerPopShow: false, // 区域下拉框
      parkId: '',
      parkName: '绑定停车场',
      parkLayerId: '',
      parkLayerName: '绑定区域',
      parkList: [], // 停车场列表
      parkListOut: [], // 车行道
      parkListIn: [], // 停车场
      parkLayerList: [], // 区域列表
      berthageOnNum: 0, // 停车场在停泊位
      berthageNum: 0, // 停车场全部泊位
      totalReceivableMoneyAmount: 0, // 实收
      totalReceivedMoneyAmount: 0, // 应收
      list: [
        { img: require('@/assets/homePage/appearanceFees.png'), title: '泊位全景' },
        { img: require('@/assets/homePage/more.png'), title: '停车订单' },
        { img: require('@/assets/homePage/qianDao.png'), title: '签到' },
        { img: require('@/assets/homePage/faultMessage.png'), title: '维护管理' }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getParkList()
    // 获取保存过的停车场信息
    if (this.$cookie.get('managePark')) {
      this.parkId = this.$cookie.get('managePark').manageParkId
      this.parkName = this.$cookie.get('managePark').manageParkName
    }
    if (this.$cookie.get('manageParkLayer')) {
      this.parkLayerId = this.$cookie.get('manageParkLayer').manageParkLayerId
      this.parkLayerName = this.$cookie.get('manageParkLayer').manageParkLayerName
      this.getParkLayerList(this.parkId)
      this.getAllBerthage()
      this.getIncome()
    }
    // this.userName = JSON.parse(window.android.getUserInfo()).userName
    this.userName = localStorage.getItem('userName')

    // 获取当前定位
    if (localStorage.getItem('userToken')) {
      window.android.startLocation()
      window.signInLocation = this.signInLocation
    }

    this.timedTask = setInterval(() => {
      setTimeout(this.getNewMessage(), 0)
    }, 60000)

    // 获取当前定位
    setInterval(() => {
      if (localStorage.getItem('userToken')) {
        window.android.startLocation()
        window.signInLocation = this.signInLocation
      }
    }, 180000)
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.signInLocation()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () {
    if (this.timedTask) {
      clearTimeout(this.timedTask)
      this.timedTask = null
    }
  }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存 功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取当前定位
    signInLocation (location) {
      const mylatitude = JSON.parse(location).latitude
      const mylongitude = JSON.parse(location).longitude
      const info = {
        longitude: mylongitude, // 经度
        latitude: mylatitude // 纬度
      }
      this.$signIn.reportLocation(info).then(() => { })
    },
    // 定时任务
    getNewMessage () {
      this.getAllBerthage()// 获取泊位
      this.getIncome()// 获取收入
    },
    // 停车场选择确认
    onSelectPark (val) {
      this.parkName = val.text
      this.parkId = val.value
      this.$cookie.set('managePark', { manageParkId: val.value, manageParkName: val.text })
      this.$cookie.remove('manageParkLayer')
      this.parkPopShow = false
      this.getParkLayerList(this.parkId)
      this.parkLayerId = ''
      this.parkLayerName = ''
      this.parkLayerList = []
      this.berthageNum = 0
      this.berthageOnNum = 0
      this.totalReceivableMoneyAmount = 0
      this.totalReceivedMoneyAmount = 0
    },
    // 点击区域
    handleClickQuyu () {
      if (this.parkLayerList.length !== 0) {
        this.parklayerPopShow = true
      } else {
        this.$toast('该停车场暂无区域')
      }
    },
    // 区域选择确认
    onSelectParkLayer (val) {
      localStorage.setItem('indexscTop', 0) // 切换后泊位全景scrollTop是0
      this.parkLayerName = val.text
      this.parkLayerId = val.value
      this.$cookie.set('manageParkLayer', { manageParkLayerId: val.value, manageParkLayerName: val.text })
      this.parklayerPopShow = false
      this.getAllBerthage()
      this.getIncome()
    },
    // 获取全部泊位
    getAllBerthage () {
      if (this.$cookie.get('managePark') && this.$cookie.get('manageParkLayer')) {
        const info = {
          parkId: this.$cookie.get('managePark').manageParkId,
          parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId
        }
        this.$homePage.getParkSpaceStatus(info).then(response => {
          if (response.resultEntity.occupiedNumber) {
            this.berthageOnNum = response.resultEntity.occupiedNumber
          }
          if (response.resultEntity.parkSpaceNumber) {
            this.berthageNum = response.resultEntity.parkSpaceNumber
          }
        })
      } else {
        this.berthageOnNum = 0
        this.berthageNum = 0
      }
    },
    // 获取停车场列表
    getParkList () {
      const info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park'
      }
      const info1 = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
        whereStr: [
          {
            colName: 'park_type_code',
            value: '1'
          }
        ]
      }
      const info2 = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
        whereStr: [
          {
            colName: 'park_type_code',
            value: '2'
          }
        ]
      }
      this.$homePage.queryParkList(info).then(res => {
        const parkList = []
        res.resultEntity.forEach(item => {
          parkList.push({ text: item.name, value: item.code })
        })
        this.parkList = parkList
      })
      // 非停车场
      this.$homePage.queryParkList(info1).then(res => {
        const parkList = []
        res.resultEntity.forEach(item => {
          parkList.push(item.code)
        })
        this.parkListOut = parkList
      })
      // 停车场
      this.$homePage.queryParkList(info2).then(res => {
        const parkList = []
        res.resultEntity.forEach(item => {
          parkList.push(item.code)
        })
        this.parkListIn = parkList
      })
    },
    // 获取区域列表
    getParkLayerList (val) {
      const info = {
        columnName: ['park_layer_id', 'park_layer_name'],
        tableName: 'tb_park_layer',
        whereStr: [{ colName: 'park_id', value: val }]
      }
      this.$queryDict.queryDict(info).then(res => {
        if (res.resultEntity.length === 0) {
          this.parkLayerName = '暂无区域'
        } else if (res.resultEntity.length === 1) {
          const parkLayerList = []
          res.resultEntity.forEach(item => {
            parkLayerList.push({ text: item.name, value: item.code })
          })
          this.parkLayerList = parkLayerList
          this.onSelectParkLayer({ text: this.parkLayerList[0].text, value: this.parkLayerList[0].value })
        } else if (res.resultEntity.length > 1) {
          const parkLayerList = []
          res.resultEntity.forEach(item => {
            parkLayerList.push({ text: item.name, value: item.code })
          })
          this.parkLayerList = parkLayerList
          if (!this.$cookie.get('manageParkLayer')) {
            this.parkLayerName = '请选择'
          }
        }
        // if (res.resultEntity.length !== 0) {
        //   const parkLayerList = []
        //   res.resultEntity.forEach(item => {
        //     parkLayerList.push({ text: item.name, value: item.code })
        //   })
        //   this.parkLayerList = parkLayerList
        //   if (!this.$cookie.get('manageParkLayer')) {
        //     this.parkLayerName = '请选择'
        //   }
        // } else {
        //   this.parkLayerName = '暂无区域'
        // }
      })
    },
    // 应收实收金额
    getIncome () {
      const info = {
        parkId: this.$cookie.get('managePark').manageParkId,
        parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId
      }
      this.$homePage.getIncome(info).then(res => {
        if (res.resultEntity === undefined) {
          this.totalReceivableMoneyAmount = 0
          this.totalReceivedMoneyAmount = 0
        } else {
          this.totalReceivableMoneyAmount = Number(res.resultEntity.totalReceivableMoneyAmount / 100).toFixed(2)
          this.totalReceivedMoneyAmount = Number(res.resultEntity.totalReceivedMoneyAmount / 100).toFixed(2)
        }
      })
    },
    // 点击登记路由跳转
    handleClickRegister () {
      // 跳转登记 in人行道 out 车行道
      if (this.$cookie.get('manageParkLayer')) {
        if (this.parkListIn.includes(this.$cookie.get('managePark').manageParkId)) {
          this.$router.push({ name: 'register', query: { page: 'in' } })
        } else {
          this.$router.push({ name: 'register', query: { page: 'out' } })
        }
      } else {
        this.$toast('请选择区域')
      }
    },
    // 点击四个按钮
    handleClickItem (index) {
      if (index === 0) {
        if (this.$cookie.get('manageParkLayer')) {
          // 跳转泊位全景 out 车行道 in人行道
          if (this.parkListIn.includes(this.$cookie.get('managePark').manageParkId)) {
            this.$router.push({ name: 'inCharge' })
          } else {
            this.$router.push({
              name: 'outCharge',
              query: { parkTypeCode: '1' }
            })
          }
        } else {
          this.$toast('请选择区域')
        }
      } else if (index === 1) {
        // 跳转停车订单
        this.$router.push('/parkingOrder')
      } else if (index === 2) {
        // 跳转签到
        this.$router.push('/signIn')
      } else if (index === 3) {
        // 跳转维护管理
        this.$router.push('/maintentManage')
      }
    },
    // 点击退出按钮
    handleClickLoginOut () {
      this.$dialog.alert({ message: '您确认要退出吗', showCancelButton: true }).then(() => {
        const that = this
        that.$login.loginOut().then(() => {
          localStorage.removeItem('userName')
          localStorage.removeItem('userCode')
          localStorage.removeItem('userToken')
          localStorage.removeItem('indexscTop')
          that.$cookie.remove('managePark')
          that.$cookie.remove('manageParkLayer')
          that.$router.replace('/login')
        })
      })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .title {
    height: 44px;
    background: #ffffff;
    font-size: 19px;
    font-weight: 500;
    color: #333333;
    padding-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //通知
  .message {
    font-size: 12px;
    height: 40px;
  }

  //登记
  .register {
    width: 50%;
    height: 230px;
    margin-top: -20px;
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .big {
      width: 180px;
      height: 180px;
      background: rgba(25, 169, 252, 0.05);
      border-radius: 50%;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: bigAnima 5s 0ms linear infinite;
      &:active {
        background: rgba(25, 169, 252, 0.1);
      }
      .mid {
        width: 140px;
        height: 140px;
        background: rgba(25, 169, 252, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: midAnima 5s 0ms linear infinite;
        &:active {
          background: rgba(25, 169, 252, 0.2);
        }
        .small {
          position: relative;
          width: 90px;
          height: 90px;
          text-align: center;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          background-image: linear-gradient(
            to right,
            #12e7ee,
            #0f9fe2,
            #209cd6,
            #47aedd
          );
          background-size: 400%;
          z-index: 1;
        }
        .small::before {
          content: '';
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: -1px;
          right: -1px;
          border-radius: 50px;
          background-image: linear-gradient(
            to right,
            #00aeff,
            #0cb2ff,
            #1bb7ff,
            #00aeff
          );
          background-size: 400%;
          z-index: -1;
          /** 设置模糊度 显示发光效果 */
          filter: blur(20px);
          animation: sun 8s infinite;
        }
      }
    }
  }
  @keyframes sun {
    100% {
      background-position: -400% 0;
    }
  }
  @keyframes bigAnima {
    0% {
      background: rgba(25, 169, 252, 0.05);
    }
    30% {
      background: rgba(25, 169, 252, 0.01);
    }
    60% {
      background: rgba(25, 169, 252, 0.01);
    }
    100% {
      background: rgba(25, 169, 252, 0.05);
    }
  }
  @keyframes midAnima {
    0% {
      background: rgba(25, 169, 252, 0.2);
    }
    20% {
      background: rgba(25, 169, 252, 0.2);
    }
    40% {
      background: rgba(25, 169, 252, 0.1);
    }
    100% {
      background: rgba(25, 169, 252, 0.2);
    }
  }

  .bottom {
    width: 93.6%;
    height: calc(100% - 44px - 2.5rem - 230px);
    margin: -30px 3.2% 0 3.2%;
    overflow-x: hidden;
    overflow-y: auto;
    .four {
      background: #ffffff;
      width: 48%;
      height: 100px;
      border-radius: 5px;
      margin-top: 10px;
      .four-img {
        margin-top: 21px;
        margin-left: 40.5%;
        height: 35px;
        width: 35px;
      }
      .four-text {
        margin-top: 5px;
        font-size: 17px;
        font-weight: bold;
        color: #333333;
        text-align: center;
      }
    }
    .information {
      width: 100%;
      height: 205px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 12px;
      .information-top {
        height: 100px;
        border-bottom: 1px solid #ebebeb;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        .information-top-lineOne {
          padding: 10px 0 0 15px;
          .loginOutButton {
            width: 18px;
            height: 18px;
            .cls-1 {
              fill: #999999;
            }
          }
        }
        .information-top-lineTwo {
          height: 30px;
          margin: 0px 0 0 15px;
          .information-top-lineTwo-img {
            height: 15px;
            width: 15px;
          }
        }
      }
      //详细信息下部
      .information-bottom {
        height: 104px;
        .vertical {
          width: 1px;
          height: 100%;
          background-color: #ebebeb;
        }
        .big {
          color: #19a9fc;
          font-size: 16px;
          font-weight: 400;
        }
        .small {
          color: #333333;
          font-size: 15px;
          font-weight: 400;
        }
        .information-bottom-lineOne {
          margin-top: 20px;
          height: 35px;
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          text-align: center;
        }
        .information-bottom-lineTwo {
          height: 30px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 10px;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
      }
    }
  }
}
</style>
